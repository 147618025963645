/* common css */
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
@font-face {
  font-family: "Reckless";
  src: url("../public/fonts/Reckless-Medium.eot") format("embedded-opentype"),
    url("../public/fonts/Reckless-Medium.otf") format("otf"),
    url("../public/fonts/Reckless-Medium.woff") format("woff"),
    url("../public/fonts/Reckless-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Matter";
  src: url("../public/fonts/Matter-Regular.eot") format("embedded-opentype"),
    url("../public/fonts/Matter-Regular.otf") format("otf"),
    url("../public/fonts/Matter-Regular.woff") format("woff"),
    url("../public/fonts/Matter-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Matter";
  src: url("../public/fonts/Matter-Medium.eot") format("embedded-opentype"),
    url("../public/fonts/Matter-Medium.otf") format("otf"),
    url("../public/fonts/Matter-Medium.woff") format("woff"),
    url("../public/fonts/Matter-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Matter SQ";
  src: url("../public/fonts/MatterSQ-Regular.eot") format("embedded-opentype"),
    url("../public/fonts/MatterSQ-Regular.otf") format("otf"),
    url("../public/fonts/MatterSQ-Regular.woff") format("woff"),
    url("../public/fonts/MatterSQ-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Matter SQ";
  src: url("../public/fonts/MatterSQ-Medium.eot") format("embedded-opentype"),
    url("../public/fonts/MatterSQ-Medium.otf") format("otf"),
    url("../public/fonts/MatterSQ-Medium.woff") format("woff"),
    url("../public/fonts/MatterSQ-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
div h1 {
  font-family: Reckless, sans-serif;
  font-size: 53px;
  line-height: 65px;
  font-weight: 500;
  color: #ffffff;
  margin: 0;
}
div h2 {
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: Reckless, sans-serif;
  font-size: 50px;
  line-height: 55px;
  font-weight: 500;
}
div h3 {
  margin-top: 0px;
  margin-bottom: 25px;
  font-family: Reckless, sans-serif;
  font-size: 40px;
  line-height: 50px;
}
div h4 {
  margin-top: 0px;
  margin-bottom: 15px;
  font-family: Reckless, sans-serif;
  font-size: 30px;
  line-height: 35px;
  font-weight: 500;
}
div h5 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: Reckless, sans-serif;
  font-size: 19px;
  line-height: 27px;
  font-weight: 500;
}
main {
  max-width: 100%;
}
.mt120 {
  margin-top: 120px !important;
}
div .text-dark {
  color: #000000 !important;
}
.matter-sans {
  font-family: "Matter", sans-serif;
}
.d-contents {
  display: contents;
}
.height_100vh {
  height: 100vh;
  overflow: hidden;
}
.bodyPart {
  background-color: #faf6f3;
  font-family: Matter, sans-serif;
  color: #000;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.2px;
}
.full-video {
  min-height: 100%;
  width: 100%;
  /* height: auto; */
  height: 100vh;
  object-fit: cover;
}
.description {
  font-size: 20px;
  line-height: 34px;
}
.fact-description {
  font-size: 13px;
  line-height: 23px;
}
.container {
  position: relative;
  display: block;
  width: 100%;
  /* max-width: 960px !important; */
  min-height: 25px;
  margin: auto;
}
button.button {
  display: inline-block !important;
  padding: 10px 55px !important;
  border-style: none !important;
  background-color: #000000 !important;
  color: #ffffff !important;
  font-size: 13px !important;
  line-height: 30px !important;
  font-weight: 400 !important;
  letter-spacing: 2.2px !important;
  text-transform: uppercase !important;
  border-radius: 0 !important;
  cursor: pointer !important;
}
button.button:hover {
  background-color: #292929 !important;
}

.page-name-dark {
  position: absolute;
  left: 0;
  right: 0;
  top: 70px;
  z-index: 15;
  color: #000;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 3.2px;
  text-transform: uppercase;
  margin: auto;
  width: fit-content;
  opacity: 0.5;
}
.page-name-dark-title {
  position: absolute;
  left: 0;
  right: 0;
  top: 70px;
  z-index: 15;
  color: #000;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 3.2px;
  text-transform: uppercase;
  margin: auto;
  width: fit-content;
}

.title {
  color: #0b0b0b !important;
  opacity: 1 !important;
}

.page-name-light {
  position: absolute;
  left: 0;
  right: 0;
  top: 70px;
  z-index: 15;
  /* color: #0b0b0b; */
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 3.2px;
  text-transform: uppercase;
  margin: auto;
  width: fit-content;
  color: #ffffff;
  /* opacity: 0.5; */
}
.page-name-light-title {
  position: absolute;
  left: 0;
  right: 0;
  top: 70px;
  z-index: 15;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 3.2px;
  text-transform: uppercase;
  margin: auto;
  width: fit-content;
  color: #ffffff;
  opacity: 0.5;
}

.background-video {
  overflow: hidden;
}
.main-div {
  position: relative;
  transition: transform 400ms ease-in-out 0s;
}
.sLwbX {
  padding-top: 60px;
  padding-bottom: 80px;
}
/* Menu Css */
.header {
  position: fixed;
  position: relative;
  width: 100%;
  display: flex;
  background: red;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
.logo-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
nav.navigation {
  z-index: 9999;
}
.nav-options {
  padding-left: 25px;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 50px;
  list-style-type: none;
}
.mobile-option {
  display: none;
}
.option :hover {
  color: rgb(22, 21, 21) !important;
}
.mobile-menu {
  display: none;
}
.logo {
  width: 45px;
  height: 45px;
}
.nav-options {
  display: flex;
  width: 100%;
  position: absolute;
  left: -100%;
  opacity: 0;
  transition: all 0.5s ease;
  flex-direction: column;
  list-style-type: none;
  grid-gap: 0px;
  padding-top: 100px;
  height: 100vh;
}
.nav-options.active {
  background-color: #000000;
  left: 0;
  opacity: 0.9;
  transition: all 0.5s ease;
  z-index: 1;
  align-content: center;
  padding-left: 0px;
  overflow: auto;
  top: 0;
}
.mobile-menu {
  color: #ffffff;
  font-size: 11px;
}
.active.container-fluid .mobile-menu .menu-icon-dark,
.container-fluid.active .mobile-menu span {
  color: #ffffff;
  z-index: 99;
}
.menu-icon {
  width: 30px;
  height: auto;
  color: #ffffff;
  cursor: pointer;
  position: absolute;
  z-index: 100;
}
.menu-icon-dark {
  width: 35px;
  height: auto;
  color: #101010;
  cursor: pointer;
  position: absolute;
  z-index: 100;
}
.text-white-dark {
  color: #000000 !important;
}
.option {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vw;
  padding: 30px 0px;
  transform-style: preserve-3d;
  opacity: 1;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  transition: opacity 200ms ease 0s, transform 200ms ease 0s;
}
.menu-item {
  display: block;
  max-width: auto;
  font-family: Reckless, sans-serif;
  color: #ffffff !important;
  font-size: 40px;
  line-height: 77px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
}
.menu-item:hover {
  opacity: 0.3;
}
.fixedHeader .find-near-me span {
  margin-top: 5px;
}
.deliver-cta {
  font-size: 14px;
}
.mobile-menu {
  display: inline-flex;
  align-items: center;
  margin-top: 5px;
}
.menu-items {
  transform-style: preserve-3d;
  opacity: 1;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  transition: opacity 700ms ease 0s, transform 700ms ease 0s;
}
/* state menu color change */
.navbar.colorChange {
  /* background-color: rgb(44, 40, 40); */
  background-color: #000000;
  min-height: 50px !important;
  transition: min-height 0.4s ease !important;
  padding-top: 0px;
  padding-bottom: 0;
}
.fixedHeader .otherColorChange .find-near-me span,
.fixedHeader .otherColorChange .mobile-menu,
.fixedHeader .colorChange .mobile-menu,
.fixedHeader .colorChange .find-near-me span {
  margin-top: 0px;
}
.navbar.otherColorChange {
  background-color: #ffffff;
  min-height: 50px !important;
  padding-top: 0;
  padding-bottom: 0;
}
.navbarDark + .nav-options.active .menu-item {
  color: #000000 !important;
}
.otherColorChange + .nav-options.active,
.colorChange + .nav-options.active {
  padding-top: 90px !important;
}
.navbarDark + .nav-options.active {
  background-color: #ffffff;
  opacity: 1;
}
/*menu css end */

/* Teams Slider */
.teams-section .slick-prev,
.teams-section .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: #9f171700;
  border: none;
  outline: none;
  background: black !important;
}
.teams-member {
  width: auto !important;
  text-align: -webkit-center;
  height: 400px;
  padding: 0 20px;
}
.middle {
  position: relative;
  visibility: hidden;
  top: -110px;
  padding: 10px 20px;
  text-align: start;
  color: white;
  font-size: 16px;
}
.teams-container .slick-list:hover .teams-member img {
  opacity: 0.5;
}
.teams-member:hover img {
  opacity: 1 !important;
}
.teams-member .middle span {
  opacity: 0.5;
}
.teams-member:hover .middle {
  visibility: visible;
}
/* End Teams slider */

/* Header part */
.fixedHeader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: transparent;
}
.fixedHeader .navbar {
  padding-left: 30px;
  padding-right: 30px;
  min-height: 100px;
  transition: min-height 0.4s ease;
}
.fixedHeader .find-cta-link {
  text-decoration: none;
  /* padding-bottom: 3px; */
  border-bottom: 1px solid #ffffff;
  font-size: 14px;
  font-family: Matter, sans-serif;
  margin-right: 80px;
}
.fixedHeader .find-cta-link-dark {
  text-decoration: none;
  /* padding-bottom: 3px; */
  border-bottom: 1px solid #070707;
  font-size: 14px;
  font-family: Matter, sans-serif;
  margin-right: 80px;
}
.fixedHeader .find-near-me {
  max-width: 305px;
  margin-left: auto;
  z-index: 9;
  position: relative;
}
.fixedHeader .find-near-me span a {
  padding: 0 15px;
}
/* /// Header part */

/* For footer */
footer {
  padding: 100px 0px;
  background-color: #fff;
  background-image: url(../public/assets/images/bird_1.png);
  background-position: 117% 89%;
  background-size: auto 225px;
  background-repeat: no-repeat;
}

.footerColor {
  background-color: #faf6f3;
}

.delivery-service {
  height: 40px;
  margin-left: 35px;
}
.delivery-service.reservebar {
  height: 26px;
}
.delivery-service.drizly {
  height: 30px;
}
.ctaDiv {
  padding: 60px 0;
  border-bottom: 1px solid #000000;
  border-top: 1px solid #000000;
  margin-bottom: 120px;
}
.cta {
  font-size: 18px;
  font-family: Reckless, sans-serif;
}
.cta-link {
  padding-bottom: 8px;
  border-bottom: 1px solid #000;
  font-family: Matter, sans-serif;
  font-size: 16px;
  text-decoration: none;
  color: #000000 !important;
}
#footer ul {
  list-style-type: none;
  padding: 0;
}
#footer ul li {
  letter-spacing: 0.2px;
  font-weight: normal;
  font-size: 12px;
  line-height: 33px;
  font-family: Matter, sans-serif;
  width: fit-content;
}
#footer ul li a {
  text-decoration: none;
  color: #000000;
}
#footer ul li a:hover {
  opacity: 0.4;
}

/* footer #footer {
  border-bottom: 1px solid #000000;
  padding-bottom: 20px;
} */
/* ///For footer */

/* Home page css (mohit) */
.index-page .hero {
  height: 100vh;
  position: relative;
}
.hero-content {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 60px;
  z-index: 50;
  width: 100%;
  padding: 0 60px;
}
.hero-content h1 {
  max-width: 440px;
  width: 100%;
}
.kicker {
  width: 100%;
  max-width: 50%;
  float: right;
  font-size: 20px;
  line-height: 33px;
  color: white;
}
/* Video Modal Play Button */
.hero-video-trigger {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  padding-bottom: 50px;
}
.play-button {
  position: relative;
  top: calc(50% - 98px);
  display: block;
  width: 70px;
  height: 70px;
  margin-right: auto;
  margin-left: auto;
  background-image: url("../public/assets/images/play.svg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translate(0px, -50%);
  cursor: pointer;
}
.play-button:hover:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 300px;
  width: 300px;
  background: radial-gradient(
    whitesmoke,
    transparent,
    transparent,
    transparent,
    transparent
  );
  opacity: 0.5;
}
/* End Video model play button  */

/* Our Whisky Slider */
#ourwhisky-slider {
  background-image: linear-gradient(
      180deg,
      hsla(26, 41%, 97%, 0.35),
      hsla(26, 41%, 97%, 0.35)
    ),
    url("/public/assets/images/product_background.jpg");
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  position: relative;
  height: 81vh;
}
button[type="button"] {
  position: relative;
  z-index: 1;
}
.section-lable {
  font-size: 13px;
  font-family: Matter, sans-serif;
  color: #000000;
  line-height: 20px;
  letter-spacing: 3px;
}
.section-lable.absolute {
  position: absolute;
  top: 30px;
  left: 30px;
}
.whiskies .oban-family-image {
  margin-top: 80px;
}
.whiskies .oban-family-image img {
  max-width: 960px;
  width: 100%;
}
#ourwhisky-slider .slick-dots {
  left: 0px;
  top: 100px;
  width: 100px;
  list-style-type: none;
}
#ourwhisky-slider .buttons span {
  font-size: 18px;
  line-height: 34px;
  font-family: Reckless, sans-serif;
}
#ourwhisky-slider .buttons span a {
  padding-bottom: 8px;
  border-bottom: 1px solid #000;
  font-family: Matter, sans-serif;
  z-index: 15555555;
  font-size: 16px;
  color: #000000;
  text-decoration: none;
}
#ourwhisky-slider .slick-list {
  height: 800px !important;
}
#ourwhisky-slider .slick-dots li {
  position: relative;
  display: list-item;
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 1px solid black;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 30px;
}
#ourwhisky-slider .slick-dots li button:before {
  font-size: 0;
}
#ourwhisky-slider .slick-dots .slick-active button:before {
  font-size: 18px;
  color: #000000;
  opacity: 1;
  top: -2px !important;
  left: -4px !important;
}
#ourwhisky-slider .slick-slider {
  padding-left: 15%;
}
#ourwhisky-slider h2 {
  margin-top: 10%;
}
#ourwhisky-slider .container {
  margin-top: 3em;
}
#ourwhisky-slider .align-middle {
  max-width: 410px;
}
#ourwhisky-slider .oban-image img {
  position: absolute;
  width: auto;
  height: 120%;
  bottom: -1px;
}
.videoSliderContent {
  position: absolute;
  z-index: 9999;
  color: #ffffff;
  margin: auto;
  width: 8.5%;
  top: 30%;
}
.videoSliderContent .textContent {
  max-width: 480px;
}
.heritageSection #pronounce {
  margin-top: 21%;
  max-width: 360px;
  margin-left: auto;
  margin-right: 120px;
}
.heritageSection .pronunciation-guide-text span {
  font-family: Reckless, sans-serif;
}
.heritageSection a {
  color: #000000 !important;
  padding-bottom: 4px;
  border-bottom: 1px solid #000000;
  text-decoration: none;
}
.heritageSection .our-heritage-link {
  color: #000000 !important;
  padding-bottom: 4px;
  border-bottom: 1px solid hsla(0, 0%, 46%, 0.11);
  text-decoration: none;
  height: 33.33vh;
  padding: 40px;
  background-color: #ffffff;
  display: block;
  width: 100%;
}
.heritageSection .our-heritage-link:hover {
  background-color: transparent;
}
.heritageSection .descImg {
  margin-left: auto;
  width: 25%;
}
.home-last-section {
  background-color: #ffffff;
  padding: 100px 60px;
}
.home-last-section .text-content p {
  margin-bottom: 25px;
}
.home-last-section .text-content {
  max-width: 360px;
  margin-left: auto;
  margin-right: 120px;
}
.home-last-section .footer-image {
  display: contents;
}
.home-last-section .text-content .content-head {
  max-width: 310px;
}
.home-last-section .inline-link,
.whisky-last-section .inline-link,
.whisky-makers .inline-link,
.section-white .inline-link {
  position: absolute;
  bottom: 10px;
}
.inline-link {
  color: #000000 !important;
  text-decoration: none;
  display: inline-block;
  padding-bottom: 5px;
  border-bottom: 1px solid #000;
}
/* /// Home page css*/

/* whiskies page css start */
.whiskies .firstSection {
  /* padding-top: 180px;
  overflow: hidden; */
}
.whiskies .where-to-buy-section {
  background-image: unset;
}
.whiskies .firstSection.hero.products {
  height: auto;
  padding-top: 180px;
  background-image: url("../public/assets/images/products_large_hero.jpg");
  background-position: 50% 100%;
  background-size: 100%;
  color: #000;
  overflow: hidden;
}
.whiskies .secondSection {
  background-color: #ffffff;
}
.container.heading {
  padding: 100px 30px;
}
.secondSection .dscDiv {
  display: flex;
  align-items: center;
}
.secondSection .dscDiv,
.secondSection .videoDiv {
  width: 50%;
  position: relative;
}
.secondSection .full-video {
  /* min-height: 100%;
  width: 100%;
  height: auto;
  max-height: 500px; */
  margin-right: 0;
  margin-left: auto;
  float: right;
  object-fit: cover;
}
.secondSection h2 {
  font-size: 40px;
}
.secondSection h2.text-dark {
  font-size: 50px;
  color: #000000 !important;
}
.preheader {
  font-size: 15px;
  letter-spacing: 3.2px;
  font-family: "Matter", sans-serif;
  display: inline-block;
  line-height: 0;
}
/* .secondSection .righrdscDiv, .secondSection .leftdscDiv{
  position: relative; 
  top: 21.5%; 
} */
.secondSection .righrdscDiv {
  max-width: 360px;
  margin: 0 0 0 120px;
}
.secondSection .leftdscDiv {
  max-width: 360px;
  margin-left: auto;
  margin-right: 120px;
}
.secondSection .anchored-cta-wrapper {
  position: absolute;
  bottom: 50px;
}
.page-where-to-buy .mapboxgl-ctrl-geocoder {
  width: 100%;
  max-width: 100%;
  border: 0.75px solid #151515;
  background-color: transparent;
  border-radius: 30px;
  margin: 30px 0 15px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-where-to-buy .mapboxgl-ctrl-geocoder--icon {
  top: 13px;
  fill: #000000;
  width: 20px;
  height: 20px;
}
.page-where-to-buy .mapboxgl-ctrl-geocoder input {
  color: #000000;
  font-weight: 700;
  margin-bottom: 0;
  font-size: 13px;
}
.page-where-to-buy .mapboxgl-ctrl-geocoder--button {
  background-color: transparent;
  top: 11px;
  width: 20px;
  height: 20px;
}
ul.suggestions {
  text-align: start;
}
ul.suggestions li {
  text-transform: uppercase;
  font-weight: bold;
}
.mapboxgl-ctrl-geocoder--powered-by {
  display: none !important;
}
.page-where-to-buy .mapboxgl-ctrl-geocoder input::placeholder {
  color: #000000;
}
.page-where-to-buy .red_btn {
  background-color: #d83e35 !important;
  border-color: #d83e35 !important;
  border-radius: 30px;
  max-width: 100%;
  width: 100%;
  outline: none;
  color: #fff;
  font-family: "Basic Commercial LT W05 Roman";
  font-style: normal;
  font-weight: 700;
  font-size: 13.5px;
  height: 45px;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
}
/* ///whiskies page css end */

/* where-buy-section */
.where-buy-section {
  padding-bottom: 180px !important;
}
.where-buy-section .inner h2 {
  font-size: 45px;
  font-weight: 600;
}
.where-buy-section #location {
  background-color: #faf6f3;
}
.where-buy-section .inner {
  text-align: center;
  max-width: 330px;
  width: 100%;
  margin: auto;
}
.inner .copy {
  text-align: center;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: 0.1em;
  margin-bottom: 15px;
  min-height: 65px;
  font-family: Arial, "system";
}
.inner .icon {
  font-size: 65px;
  color: #000000;
  margin: 20px 0 30px;
}
.inner .icon .svg-inline--fa path {
  fill: #000000;
}
.inner .copy p {
  color: #000000;
}
.mapboxgl-ctrl-geocoder {
  width: 100% !important;
  max-width: 100% !important;
  border: solid 1px #000000;
  background-color: transparent !important;
  border-radius: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  height: 50px;
}
.mapboxgl-ctrl-geocoder--icon {
  top: 13px !important;
  fill: #000000 !important;
  width: 20px !important;
  height: 20px !important;
}
div.mapboxgl-ctrl-geocoder input {
  font-size: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #333333;
  line-height: 1.42857143;
  font-family: "Matter", sans-serif;
}
.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
  top: 13px !important;
  width: auto;
}
.where-buy-section button {
  width: 100%;
}
/* /// where-buy-section css end */
.whisky-last-section {
  background-color: #ffffff;
  padding: 100px 0;
}
.whisky-last-section .text-content {
  max-width: 340px;
}
/*/// where-buy-section */

.distillery-page {
  overflow: hidden;
}
.distillery-page .bg_firstSection {
  background-image: url(../public/assets/images/distillery_background.jpg);
  background-size: 80% 100%;
  background-repeat: no-repeat;
  position: relative;
  background-position: 50% 100%;
  height: 100vh;

  /* background-size: 80% 90%;
  background-repeat: no-repeat;
  position: relative;
  background-position: 50%;
  height: 100vh; */
}
.distillery-page .firstSection .textContain {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 60px;
  z-index: 50;
}
.distillery-page .secondSection {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.76),
      rgba(25, 25, 25, 0) 21%
    ),
    url("../public/assets/images/distillery_background_1.jpg");
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-attachment: scroll, fixed;
  color: #fff;
  text-align: center;
  padding: 100px 0;
}
.distillery-page .secondSection p {
  max-width: 610px;
  margin: 0 auto 25px;
}
.distillery-page .secondSection button.button {
  border: 1px solid #ffffff !important;
  background-color: transparent !important;
}
.tertiary-heading {
  margin-bottom: 36px;
  font-family: Matter, sans-serif;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 6.2px;
  text-transform: uppercase;
}
.distillery-page .thirdSection,
.teams-container {
  padding-top: 100px;
}
.distillery-page .thirdSection .videoDiv video {
  width: 100%;
  min-height: 100%;
  min-height: 610px;
  object-fit: cover;
}
.distillery-page .quoteDiv {
  max-width: 350px;
  margin-left: auto;
  padding: 35px 0;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}
.distillery-page .fourthSection {
  margin-top: 90px;
  background-color: #faf6f3;
}
.large-quote {
  margin-bottom: 25px;
  padding: 0px;
  border-left-style: none;
  font-family: Reckless, sans-serif;
  font-size: 23px;
  line-height: 41px;
}
.component-number {
  width: 35px;
  height: 35px;
  margin-bottom: 15px;
  padding-top: 2px;
  border: 1px solid #000;
  border-radius: 900px;
  text-align: center;
}
.distillery-page .that-why {
  background-color: #faf6f3;
  padding: 120px 0 100px;
}
.distillery-page .we-never {
  background-image: url(../public/assets/images/we-never-bg.jpg);
  background-size: cover;
  padding: 100px 0 35px;
  background-position: center;
  height: 550px;
}
.distillery-page .we-never .cta.underline {
  position: absolute;
  bottom: -175px;
}
.large-quote.larger {
  font-size: 40px;
  line-height: 63px;
  color: #ffffff;
}
.cta.underline {
  display: inline-block;
  padding-bottom: 15px;
  border-bottom: 1px solid #fff;
  color: #ffffff !important;
  text-decoration: none;
  font-family: Reckless, sans-serif;
}
.distillery-slider .slick-slide img {
  margin: auto;
}
.slick-arrow {
  height: 50px !important;
  width: 50px !important;
  border-radius: 50%;
  cursor: pointer;
  z-index: 100;
  color: #000000 !important;
}
.slick-next {
  transform: rotate(180deg) !important;
}
.slick-prev:before,
.slick-next:before {
  /* content: "\2039" !important; */
  content: "‹" !important;
  font-size: 50px !important;
  color: #000000 !important;
}
/*.slick-disabled{
    display: none !important;
} */
.distillery-slider {
  background-color: #ffffff;
  padding: 100px 0;
}
.distillery-page .slick-dots,
.people-container .slick-dots {
  list-style-type: none;
  display: flex !important;
  bottom: 0;
  justify-content: center;
}
.distillery-slider .slick-list {
  height: 600px !important;
}
.distillery-page .slick-dots li,
.people-container .slick-dots li {
  display: list-item;
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 1px solid black;
  margin: 10px 7px;
  border-radius: 30px;
}
.teams-container .slick-arrow {
  top: 34% !important;
}
.teams-container .slick-next {
  top: 44% !important;
}
.distillery-page .slick-dots li button:before,
.people-container .slick-dots li button:before {
  font-size: 0;
}
.distillery-page .slick-dots .slick-active button:before,
.people-container .slick-dots .slick-active button:before {
  font-size: 19px;
  color: #000000;
  opacity: 1;
  top: -2px !important;
  left: -3px !important;
}
.teams-container .slick-slider {
  padding: 0 25px;
}
.learn-more-button.button {
  background-color: transparent !important;
  border: 1px solid #000000 !important;
  color: #000000 !important;
}
.learn-more-button.button:hover {
  background-color: #000000 !important;
  color: #ffffff !important;
}
/* ///distillery-page */

/* product-details */
.product-details .wheretobuy-section {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  background-image: url("../public/assets/images/where_to_buy_bcg.jpg");
  background-position: 50% 0px;
  background-size: 100%;
  background-repeat: no-repeat;
}
.product-details .firstSection {
  padding: 180px 30px 100px;
}
.awards-preview .seal-image {
  width: auto;
  height: 37px;
  margin-right: 16px;
  float: none;
}
.legal-drink-info {
  margin: 30px 0;
  font-size: 12px;
}
.flavor-tags {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 8px;
  padding: 9px;
  border-style: dashed;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.11);
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 2.2px;
  text-transform: uppercase;
}
button.accordion-button {
  padding: 0;
  height: 40px !important;
  border: 0;
  background-color: transparent !important;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 3.2px;
  font-family: Matter, sans-serif;
  border-radius: 0 !important;
  color: #000000 !important;
  border-color: #000000 !important;
  box-shadow: unset !important;
}
.accordion-item,
.accordion-header {
  border-left: 0 !important;
  border-right: 0 !important;
  background-color: transparent !important;
  border-radius: 0 !important;
}
.accordion-item:first-of-type {
  border-top: 0;
}
.accordion-button::after {
  background-image: var(--bs-accordion-btn-icon) !important;
}
.flavor-description {
  opacity: 0.73;
  font-size: 12px;
  line-height: 21px;
}
.product__awards-reviews {
  background-image: url(../public/assets/images/product-review-awards-bg.png);
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  color: #ffffff;
  padding: 100px 0;
}
.award-name {
  font-size: 12px;
  line-height: 17px;
}
.award-img-des {
  margin-bottom: 60px;
}
.component-heading {
  margin-bottom: 60px;
  font-family: Matter, sans-serif;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 3.2px;
  text-transform: uppercase;
}
.component-heading.margin-bottom {
  margin-bottom: 60px;
}
.block-quote {
  display: inline;
  margin-bottom: 0px;
  padding: 0px;
  border-left-style: none;
  opacity: 0.65;
  font-size: 13px;
  line-height: 23px;
}
.award-information .quote {
  margin-top: 20px;
  position: relative;
}
.award-information .quote-open {
  position: absolute;
  left: -22px;
  top: 8px;
}
.product__awards-reviews .margin-bottom:first-of-type {
  margin-bottom: 60px;
}
.lastBottleSection {
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 100px;
}
.related-products {
  align-items: end;
  height: 750px;
}
.related-products .related-product {
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: #000000;
  bottom: -8px;
  max-width: 200px;
  width: 100%;
}
.related-products .related-product-image {
  width: auto;
  height: 500px;
  display: block;
}
.related-product-information {
  position: absolute;
  top: -130px;
  right: -222px;
  z-index: 100;
  width: 220px;
  height: 143px;
  text-align: left;
  opacity: 0;
}
.related-product-information .lineImg {
  position: absolute;
  top: 12px;
  left: -105px;
}
.related-product:hover .related-product-information {
  opacity: 1;
}
/* /// product-details */

/* heritage-section-tab  strat*/
.heritage-section-tab {
  border-top: 1px solid hsla(0, 0%, 46%, 0.11);
  border-bottom: 1px solid hsla(0, 0%, 46%, 0.06);
  position: relative;
  top: -2px;
}
.heritage-image-bg {
  background-image: url("../public/assets/images/heritage_hero.jpg");
  background-size: 80% 90%;
  background-repeat: no-repeat;
  position: relative;
  background-position: 50%;
  height: 100vh;
}
.heritage-image {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 40px;
}
.heritage-section-tab .nav.nav-tabs {
  padding: 60px 30px;
  display: flex;
  justify-content: space-between;
  max-width: 960px;
  margin: auto;
}
.heritage-section-tab .nav.nav-tabs li button {
  display: inline-block;
  border-bottom: 1px solid;
  border-color: transparent;
  opacity: 0.35;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: #000000;
  background-color: transparent;
  padding: 8px 0;
}
.heritage-section-tab .nav.nav-tabs li button.active {
  border-bottom-color: #000;
  opacity: 1;
}
.conterDiv {
  height: 752px;
  position: relative;
}
.conterDiv .img-fluid {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-counter {
  background-color: #000000;
  color: #ffffff;
  max-width: 70px;
  justify-content: space-around;
  padding: 5px;
  float: right;
  width: 100%;
  position: absolute;
  top: 30px;
  right: 30px;
}
.image-counter .imageNum {
  font-size: 15px;
  text-align: center;
}
.borderDiv {
  opacity: 0.42;
}
.factsDataTitle.container,
.factsDataTitle {
  background-color: #ffffff;
  color: #000000;
  box-shadow: 0 13px 17px -18px rgb(0 0 0 / 19%);
  position: sticky;
  top: 0;
  z-index: 9;
  max-width: 100% !important;
}
.factsDataTitle .row {
  padding: 10px 0;
}
.factsDataTitle h3 {
  font-size: 30px;
}
.searchbox {
  width: 100%;
  height: 100%;
  max-width: 250px;
  float: right;
  border-style: none;
  background-image: url("../public/assets/images/search.png");
  background-position: 87% 50%;
  background-size: 20px;
  background-repeat: no-repeat;
  font-size: 12px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  outline: 0;
  background-position-x: calc(100% - 40px) !important;
  /* height: 22px; */
}
.factsDiv {
  height: 752px;
  overflow: auto;
  position: relative;
}

.factsDiv::-webkit-scrollbar,
.nav-options.active.nav::-webkit-scrollbar {
  display: none;
}
.factsData .factsItem {
  padding: 40px;
  border-bottom: 1px solid hsla(0, 0%, 46%, 0.11);
  background-color: #ffffff;
  cursor: pointer;
  max-height: 270px;
}
/*  */
.factsData .factsItem:hover,
.factsData .factsItem:first-of-type {
  background-color: #faf6f3;
}

.factsData .factsItem {
  background-color: #ffffff !important;
}
.factsData .active {
  background-color: #faf6f3 !important;
}

.gradient-cutoff {
  position: sticky;
  left: 1px;
  right: 0px;
  bottom: 0px;
  z-index: 10;
  height: 120px;
  background-image: -webkit-linear-gradient(
    270deg,
    hsla(0, 0%, 100%, 0),
    #fff 50%
  );
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff 50%);
  text-align: center;
}
.scroll-icon {
  position: relative;
  top: 50%;
  height: 30px;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}
.whisky-makers {
  padding: 100px 0;
  background-color: #ffffff;
}
.whisky-makers-image .img-fluid {
  width: 450px;
  height: 490px;
  object-fit: fill;
}
/* /// heritage-section-tab */

/* people-container */
.people-container .bg_people-image {
  background-image: url("../public/assets/images/oban_people.jpg");
  background-size: 80% 90%;
  background-repeat: no-repeat;
  position: relative;
  background-position: 50%;
  height: 100vh;
}
.people-container .people-image {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
}
.people-container .teams-container,
.video-section {
  border-bottom: 0.8px solid rgba(0, 0, 0, 0.1);
  padding: 100px 0;
}
.our-values {
  padding: 100px 0;
}
.component-heading {
  margin-bottom: 15px;
  font-family: Matter, sans-serif;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 3.2px;
  text-transform: uppercase;
}
.p-values {
  opacity: 0.69;
  font-size: 12px;
  line-height: 23px;
}
.our-values .description {
  margin-bottom: 80px;
}
.textDiv {
  padding: 30px 18px;
}
.section-white {
  background-color: #ffffff;
  padding: 100px 0;
}
.section-white .textDiv {
  max-width: 380px;
}
.video-section {
  background-color: #faf6f3;
}
.section-label {
  position: absolute;
  left: 42px;
  top: 30px;
  z-index: 10;
  margin-bottom: 0px;
  font-family: Matter, sans-serif;
  color: #fff;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 500;
}
/* /// people-container */

/* where-to-buy-section  */
.where-to-buy .heading h1 {
  font-size: 53px;
}
.where-to-buy-section {
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(
      180deg,
      rgba(250, 247, 244, 0.35),
      rgba(250, 247, 244, 0.35)
    ),
    url(../public/assets/images/product_background.jpg);
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
}
/* /// Where to buy */

/* newsLetter-container */
.newsLetter-container {
  padding: 80px 0 50px;
}
.newsLetter-container .stayTouch h1.text-dark {
  margin-bottom: 30px;
}
.newsLetter-container .stayTouch p.text-center {
  margin-bottom: 25px;
}
.newsLetter p {
  font-size: 12px;
  line-height: 1.2;
}
.newsLetter .modal-content {
  border-radius: 0 !important;
  background-color: #faf6f3;
}
.newsLetter .modal-body {
  padding: 0 50px;
}
.newsLetter .modal-content .form-check label {
  font-weight: normal;
  font-size: 16px;
}
.newsLetter.modal p {
  font-size: 16px;
  margin-bottom: 25px;
}
.newsLetter input.form-control,
.newsLetter select.form-select,
.newsLetter textarea.form-control {
  border-radius: 5px;
  border: 2px solid #000000;
  padding: 9px;
  box-shadow: none;
  border-bottom-color: rgb(118, 118, 118);
}
.newsLetter .touchForm span {
  color: #f10d0d;
  font-size: 13px;
  font-weight: bold;
}

/* .newsLetter.modal,.modal {
  z-index: 99999 !important;
} */
.newsLetter .form-check input {
  box-shadow: none;
  width: 13px;
  height: 13px;
  border-color: rgb(51, 51, 51);
  margin-top: 7px;
  cursor: pointer;
}
.newsLetter .form-check label {
  font-size: 16px;
  text-align: left;
  font-weight: 600;
  width: fit-content;
  cursor: pointer;
  margin-bottom: 7px;
}
.newsLetter .btnDiv .btn-lg {
  max-width: 200px;
  width: 100%;
  height: 40px;
  line-height: normal;
  padding: 6px;
}
.newsLetter button.btn {
  padding: 10px 30px;
  display: block;
  background-color: #d4d5d4 !important;
  font-weight: bold;
  border-radius: 5px;
  border-color: transparent !important;
  color: #000000 !important;
  margin: 20px auto;
}


.recapta-group div div div {
  height: 80px!important;
  text-align: center;
  width: 100%!important;
}
#salesforceForm select#\30 0N4v00000C2nYa,
#salesforceForm select#\30 0N4v00000C2h5k,
#salesforceForm select#\30 0N4v00000C2h5p,
#salesforceForm select#\30 0N4v00000C2h5p{
    margin-bottom: 0rem!important;
}
/* /// newsLetter-container css start end */

/* corona warning css start */
.corona-warning {
  background-color: #000;
  bottom: 0;
  color: #fff;
  max-height: 100vh;
  padding: 20px;
  position: fixed;
  width: 100%;
  z-index: 99;
}
/* corona warning css end */

/* validation error */
.required_text {
  color: red;
}
/* validation error */

/* newsletter modal  */
.newsLetter .modal-header .closeBtn {
  position: absolute;
  right: 5px;
  top: 0;
  font-size: 24px;
  background: transparent !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  z-index: 1000000;
}

/* css Added by Avinash */
.mobile-menu span {
  z-index: 99;
}
.otherColorChange .nav-options.active {
  background-color: rgba(255, 255, 255, 0.8) !important;
}
.ourWhiskySection,
#ourwhisky-slider {
  height: 100vh;
}
#ourwhisky-slider h2 {
  margin-top: 0;
}
#ourwhisky-slider .slick-slide .row .wiskey-slider-text:first-child {
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
}
#ourwhisky-slider .buttons span {
  position: absolute;
  bottom: 33px;
}
#ourwhisky-slider .slick-dots {
  top: 50%;
  transform: translate(0px, -50%);
  bottom: 0;
  height: 110px;
}
#ourwhisky-slider .slick-list {
  height: 100vh !important;
}
.ourWhiskySection .slick-vertical .slick-slide {
  height: 100vh;
}
#ourwhisky-slider .slick-slide.slick-active.slick-current {
  z-index: 99;
}
#ourwhisky-slider .slick-list {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.heritageSection .our-heritage-link {
  min-height: 33.33vh;
  height: auto;
}
.heritageSection .our-heritage-link .description-flex {
  margin-right: 40px;
  width: 60%;
}
.heritageSection #pronounce {
  height: calc(100% - 23.6%);
  position: relative;
}
.heritageSection .pronunciation a {
  /* position: absolute; */
  bottom: 35px;
}
/* ==== New Slider ==== */
.exploreSection {
  position: relative;
}
.exploreSection .sliderContainer > div > div {
  display: flex;
  width: 100%;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.exploreSection .sliderContainer {
  position: relative;
}
.exploreSection .sliderContainer > div:first-child {
  display: flex;
}
.exploreSection .slider {
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
}
.exploreSection .slick-slide {
  color: #ffffff;
  font-size: 30px;
  text-align: center;
}
.exploreSection .slick-prev {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0px;
  margin: auto;
  height: 120px !important;
  width: 60px !important;
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff !important;
  border-top-right-radius: 75px;
  border-bottom-right-radius: 75px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transition: 0.4s;
  transform: translate(0, 0%);
}
.exploreSection .slick-prev:hover,
.exploreSection .slick-next:hover,
.exploreSection .slick-prev:focus,
.exploreSection .slick-next:focus {
  background-color: rgba(255, 255, 255, 0.55) !important;
  transition: 0.4s;
}
.slick-prev:before {
  opacity: 1 !important;
  margin-right: 18px;
  line-height: 110px !important;
}
.slick-next:before {
  opacity: 1 !important;
  margin-right: 18px;
  line-height: 110px !important;
}
.slick-prev:before,
.slick-next:before {
  color: #ffffff !important;
  opacity: 1 !important;
  height: 120px;
  display: block;
  font-family: "inherit" !important;
  position: relative;
}
.exploreSection .slick-next {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  height: 120px !important;
  width: 60px !important;
  background-color: rgba(255, 255, 255, 0.4) !important;
  color: #ffffff !important;
  border-top-right-radius: 75px;
  border-bottom-right-radius: 75px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transition: 0.4s;
}
.exploreSection .slick-dots {
  bottom: -30px;
}
.exploreSection .progressBarContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  margin: auto;
  padding: 50px 120px;
  z-index: 9;
}
.exploreSection .progressBarContainer .item {
  display: block;
  width: 30%;
  padding: 0;
  cursor: pointer;
  margin-right: 5px;
  margin-left: 6px;
  float: left;
  color: white;
  position: relative;
}
.exploreSection .progressBarContainer .item:last-child {
  margin-right: 0;
}
.exploreSection .progressBarContainer .item span.progressBar {
  width: 100%;
  height: 1px;
  background-color: hsla(0, 0%, 100%, 0.18);
  display: block;
}
.exploreSection .progressBarContainer .item span.progressBar .inProgress {
  background-color: rgba(255, 255, 255, 1);
  width: 0%;
  height: 1px;
}
.round-dots {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: transparent;
  display: block;
  border: 1px solid #ffffff;
  position: absolute;
  left: -11px;
  top: -5px;
  bottom: 0;
}
.item.yellow-text .round-dots {
  background-color: #ffffff;
}
.exploreSection .sliderContainer .slick-initialized .slick-slide {
  position: relative;
}
.exploreSection .sliderContainer .slider-text {
  position: absolute;
  right: 15%;
  top: 30vh;
  margin: auto;
  width: 50%;
  max-width: 480px;
  min-height: 25px;
  display: block;
  text-align: left;
}
.exploreSection .sliderContainer .slider-text h3 {
  margin-top: 0px;
  margin-bottom: 25px;
  font-family: Reckless, sans-serif;
  font-size: 40px;
  line-height: 50px;
  font-weight: 500;
  color: #ffffff;
}
.exploreSection .sliderContainer .slider-text p {
  margin-bottom: 0;
  font-size: 20px;
  line-height: 34px;
  color: #fff;
}
.heritageSection .section-lable {
  margin-top: 30px;
  display: block;
}
.limited-edition-legend {
  margin-bottom: 30px;
  opacity: 0.5;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 2.2px;
  text-transform: uppercase;
}
.hero.products .description {
  margin-bottom: 25px;
}
/* ==== Peple SLider ==== */
.people-slider-wrapper {
  max-width: 960px;
  margin: auto;
}
.people-slider-wrapper .slick-arrow {
  display: none !important;
}
.exploreSection.people-slider-wrapper .slider {
  height: auto;
}
.people-name {
  position: absolute;
  z-index: 1;
  bottom: 10px;
  left: -5px;
}
.people-name .fullname-user {
  position: relative;
}
.people-name .usern-nm {
  display: block;
  width: 100%;
  font-size: 12px;
  white-space: nowrap;
  /* display: none; */
  transition: display 0.4s ease;
}
.usern-fn {
  position: absolute;
  bottom: 20px;
  display: block;
  width: 100%;
  white-space: nowrap;
  transition: bottom 0.4s ease;
  font-size: 15px;
}
.item.yellow-text .people-name .usern-fn {
  bottom: 20px;
  transition: bottom 0.4s ease;
}
.item.yellow-text .people-name .usern-nm {
  display: block;
  transition: display 0.4s ease;
}
.teams-container .slick-arrow {
  position: absolute;
}
.teams-container .slick-prev:before {
  color: #000000 !important;
}
.teams-container .slick-next:before {
  color: #000000 !important;
}
.play-button-people {
  position: absolute;
  top: 50%;
  display: block;
  width: 70px !important;
  cursor: pointer;
  height: 70px;
  margin-right: auto;
  margin-left: auto;
  background-image: url("../public/assets/images/play.svg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  z-index: 10;
  left: 0;
  right: 0;
}
.play-button-people:hover:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 300px;
  width: 300px;
  background: radial-gradient(
    whitesmoke,
    transparent,
    transparent,
    transparent,
    transparent
  );
  opacity: 0.5;
}
.teams-member:hover img {
  transition: opacity 0.6s;
}
.teams-member .middle {
  transition: transform 0.4s ease-out;
  transform: translate(0px, 6px);
}
.teams-member:hover .middle {
  visibility: visible;
  transition: transform 0.4s ease-out;
  transform: translate(0px, 0px);
}
.teams-member .middle h4 {
  margin-bottom: 7px;
  font-size: 24px;
  line-height: 25px;
}
.teams-member .middle .team-member-position {
  opacity: 0.62;
  font-size: 14px;
}
/* people css end */

/* Modal view homepage ==== */
.video-modal {
  max-width: 100% !important;
  padding-left: 60px;
  padding-right: 60px;
  background-color: #000000;
  top: 0;
  margin-top: 0 !important;
  padding-top: 40px;
  padding-bottom: 40px;
  min-height: 100vh;
  margin-bottom: 0 !important;
}
.video-modal .full-video {
  min-height: inherit;
  max-height: calc(100vh - 82px);
  background-color: #000000;
}
.video-modal .exit-button {
  background-color: transparent;
  position: absolute;
  z-index: 99;
  border: 2px solid #ffffff;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  color: #ffffff;
  font-size: 36px;
  top: -21px;
  right: -26px;
}
.fixedHeader .nav-options.active {
  padding-top: 115px;
  transition: opacity 0.9s ease, padding-top 0.5s ease !important;
}
header .logo {
  position: absolute;
  left: -66px;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  padding-top: 7px;
  cursor: pointer;
  z-index: 9;
}
.hero-content {
  bottom: 45px;
}
.hero-content .hero-heading-wrapper:first-child {
  margin-bottom: 30px;
}
.heritageSection .pronunciation a {
  bottom: 85px;
}
/* Modal view homepage end */

/* css in people slider */
.pause-button-people {
  position: absolute;
  top: 50%;
  display: block;
  width: 70px !important;
  cursor: pointer;
  height: 70px;
  margin-right: auto;
  margin-left: auto;
  background-image: url("../public/assets/images/pause.svg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  z-index: 10;
  left: 0;
  right: 0;
}
.pause-button-people:hover:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 300px;
  width: 300px;
  background: radial-gradient(
    whitesmoke,
    transparent,
    transparent,
    transparent,
    transparent
  );
  opacity: 0.5;
}
.distillery-slider .slick-slide {
  padding: 0 80px;
}
.distillery-slider .slick-list {
  padding: 0 -80px;
}
.distillery-slider .slick-slide img {
  margin: 0 !important;
  width: 100%;
  padding: 0 30px;
}

.tab-content {
  border-top: 1px solid hsla(0, 0%, 46%, 0.11);
}
.nav-tabs {
  border-bottom: none !important;
}
div#react-aria693066630-1-tabpane-All {
  height: 100vh;
}
.distillery-slider-parent .slick-slider button.slick-next {
  position: absolute;
  top: 271px;
  right: 195px;
}
.distillery-slider-parent .slick-slider button.slick-prev {
  position: absolute;
  top: 224px;
  left: 190px;
}
.progress-container {
  display: flex;
}
#myProgress1,
#myProgress2,
#myProgress3 {
  width: 33%;
  background-color: #ffffff;
}
#myBar1,
#myBar2,
#myBar3 {
  width: 1%;
  height: 1px;
  background-color: #ffffff;
}
.wrapper {
  border: 3px solid blue;
}
.barCompleted {
  background-color: #ffffff;
  width: 100%;
}
.label {
  font-size: 20px;
  color: green;
}
.pb-container {
  width: 90vw;
  display: flex;
  justify-content: space-between;
}
.pb-container div[role="progressbar"] {
  width: 32%;
}
.round-dot-home {
  width: 10px;
  height: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border: 1px solid #fff;
  border-radius: 900px;
  opacity: 0.5;
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  position: relative;
  top: -4px;
  bottom: 0;
  z-index: 9;
  /* appearance: none !important; */
  display: block;
}
@supports (-webkit-touch-callout: none) {
  .round-dot-home {
    appearance: none !important;
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: "inherit" !important;
  }
}
.home-video-active {
  background-color: #ffffff;
  opacity: 1;
}
.arrows {
  width: 48px;
  height: 48px;
  border-color: #000;
  position: absolute;
  top: 50%;
  margin-top: -31px;
}
.prev {
  border-bottom: 6px solid;
  border-left: 6px solid;
  transform: rotate(45deg);
  left: 10px;
}
.next {
  border-bottom: 6px solid;
  border-left: 6px solid;
  transform: rotate(-135deg);
  right: 10px;
}
.content.find-near-me {
  padding-top: 180px;
  /* margin-top: 95px; */
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(
      180deg,
      rgba(250, 247, 244, 0.35),
      rgba(250, 247, 244, 0.35)
    ),
    url(../public/assets/images/product_background.jpg);
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
}

.progressBarContainer .d-inlineBlock {
  display: inline-block !important;
  width: 14.28%;
  position: relative;
}
.us-name {
  position: absolute;
  color: #ffffff80;
  top: 18px;
}
div[role="progressbar"] > div:first-child {
  background: #ffffff6e !important;
}
.progressLine > div:first-child {
  bottom: 0;
  left: 10px;
  margin: auto;
  position: absolute;
  top: -7px;
  width: calc(100% - 10px) !important;
}
.progressLine > div:first-child > div {
  width: 0;
}
.backBtn {
  background: transparent;
  border: 0;
  position: relative;
  left: 0;
  text-align: start;
  padding-bottom: 10px;
  width: fit-content;
}
.people-container .sliderContainer .full-video {
  height: 540px;
}
.newsLetter input[type="date"]::before {
  content: attr(placeholder);
  position: absolute;
  color: var(--boxAfterBackColor, #5c6268);
}
.newsLetter input[type="date"]:focus {
  color: #212529 !important;
}
.newsLetter .input,
.newsLetter input[type="date"] {
  position: relative;
}
.newsLetter input[type="date"]:before {
  content: attr(placeholder);
  position: absolute;
  left: 1px;
  right: 33px;
  top: -3px;
  bottom: 1px;
  padding: 10px;
  /* color: #000000; */
  background-color: #ffffff;
}
.newsLetter input[type="date"]:focus::before {
  content: none;
}
.newsLetter .rem-placeholder::before {
  content: none !important;
  display: none !important;
}
.error input[type="checkbox"] {
  outline: 1px solid #c93c2b;
  box-shadow: 0 0 4px #c93c2b!important;
  border-color: #c93c2b;
}

/* age gate modal */
#age_select_year_of_birth {
  border: 1px solid !important;
}
/* for dyinymic footer */
#footer #dg_footer_wrapper {
  background-color: transparent;
}
#footer #dg_footer_wrapper #dg_footer_wrapper_inner {
  text-align: left;
  width: 100%;
}
#footer #dg_footer_wrapper #dg_footer_wrapper_inner .dg_footer_row_0 {
  padding: 0;
  margin: 0;
  letter-spacing: 0.2px;
  font-weight: normal;
  font-size: 12px;
  line-height: 33px;
  font-family: Matter, sans-serif;
}
#footer #dg_footer_wrapper #dg_footer_wrapper_inner .dg_footer_row_0 a {
  color: #000;
}
#footer #dg_footer_wrapper #dg_footer_wrapper_inner .dg_footer_row_0 a:hover {
  opacity: 0.4;
  text-decoration: none;
}
#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
}
#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li:after {
  content: "";
}
#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li a {
  padding: 0;
}
#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li span {
  color: #000;
}
#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_1,
#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_3,
#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_5 {
  width: 60%;
}
#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_7 {
  width: 60%;
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}
#footer
  #dg_footer_wrapper
  #dg_footer_wrapper_inner
  ul
  li.dg_footer_item_7
  span {
  padding: 0;
  display: inline-block;
}
#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_8 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
  width: 100%;
}
#footer
  #dg_footer_wrapper
  #dg_footer_wrapper_inner
  ul
  li.dg_footer_item_8#dg_THE_BAR {
  font-size: 12px;
  font-style: normal;
  line-height: 33px;
}
#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_last {
  width: 100%;
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
  color: black;
  margin-top: 15px;
  opacity: 0.4;
  margin-bottom: 15px;
}
#footer
  #dg_footer_wrapper
  #dg_footer_wrapper_inner
  ul
  li.dg_footer_item_last
  span {
  padding: 0px;
}

/* Menu  */
.social-links-wrapper--mobile {
  display: none;
}

h1.hero-heading.product.text-dark {
  margin-bottom: 14px;
}
.bg-home-slider .background-video .full-video,
.index-page .hero {
  height: 100vh !important;
}
/* new css add  */
.factsData .factsItem {
  display: flex;
  flex-direction: row;
}
.factsData .factsItem .factsDesc {
  width: 70%;
  display: flex;
  align-items: center;
  padding-right: 40px;
}
.factsData .factsItem .factsImg {
  width: 100%;
  height: auto;
  max-width: 30%;
  min-height: 190px;
  flex: 0 0 auto;
  background-position: 50% 50%;
  background-size: cover;
}
.accordion-button::after {
  background-size: 100% 80% !important;
}

.distillery-slider .slick-prev:before,
.distillery-slider .slick-next:before {
  color: #000000 !important;
}

.description.mt-30 {
  margin-top: 12px;
}

/* Minal */
.absoluteHeader {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: transparent;
}

.absoluteHeader .navbar {
  padding-left: 30px;
  padding-right: 30px;
  min-height: 100px;
}
.absoluteHeader .find-cta-link {
  text-decoration: none;
  /* padding-bottom: 3px; */
  border-bottom: 1px solid #ffffff;
  font-size: 14px;
  font-family: Matter, sans-serif;
  margin-right: 80px;
}
.absoluteHeader .find-cta-link-dark {
  text-decoration: none;
  border-bottom: 1px solid #070707;
  font-size: 14px;
  font-family: Matter, sans-serif;
  margin-right: 80px;
}
.absoluteHeader .find-near-me {
  max-width: 305px;
  margin-left: auto;
  z-index: 9;
  position: relative;
}
.absoluteHeader .find-near-me span a {
  padding: 0 15px;
}
.eMargin label{
  margin-left: 12px;
}
select[value=""] {
  color: red;
}
/* neww css for multiselect dropdown */
.multselect-dropdown .css-13cymwt-control, .multselect-dropdown .css-t3ipsp-control {
  border: 2px solid #000000 !important;
  border-radius: 5px;
  border: 2px solid #000000;
  padding: 1.5px !important;
  box-shadow: none;
  border-bottom-color: rgb(118, 118, 118) !important;
}
select option:first-child {
  color: red; /* Change the color to your desired color */
}

.newsLetter input[type="date"]::before, input {
  color: var(--boxAfterBackColor, #5c6268);
}
.react-datepicker-wrapper{
  width: 100%;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after,.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  left: -15px !important;
}

.calender-place {
  position: absolute;
  z-index: 9;
  right: 14px;
  top: 14px;
}

.legal-drink-info p{
  font-family: Matter;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.2px;
  }