/* Changes by AVI */
/* .cta-wrapper .inline-link{
	margin-top: 80px;
	position: relative;
} */
/* Changes by AVI Ends */
/* New changes*/
.deliver-cta {
	font-size: 14px;
	letter-spacing: 1.2px;
}
.navbar #hide {
    margin-top: 7px;
}
/* New changes ends*/

@media only screen and (min-width: 1500px) {
	.distillery-slider-parent .slick-slider button.slick-next{
		right: 20%;
	}
	.distillery-slider-parent .slick-slider button.slick-prev{
		left: 20%;
	}
}

@media only screen and (min-width: 1990px) {
	.distillery-slider-parent .slick-slider button.slick-next{
		right: 25%;
	}
	.distillery-slider-parent .slick-slider button.slick-prev{
		left: 25%;
	}
}
@media only screen and (min-width: 2300px) {
	.distillery-slider-parent .slick-slider button.slick-next{
		right: 30%;
	}
	.distillery-slider-parent .slick-slider button.slick-prev{
		left: 30%;
	}
}
@media only screen and (min-width: 1990px) {
	.distillery-slider-parent .slick-slider button.slick-next{
		right: 25%;
	}
	.distillery-slider-parent .slick-slider button.slick-prev{
		left: 25%;
	}
}
@media only screen and (max-width: 1320px) {
	.related-products div:last-child .related-product-information {
		right: 200px;
	}

	.related-products div:last-child .related-product-information img {
		transform: rotateY(180deg);
		left: 225px;
		right: 0;
	}
	.main.where-to-buy .image-footer-responsive{
		max-width: 100%;
		height: 100%;
	}
}

@media only screen and (min-width: 1240px) {
	.teams-container .slick-dots {
		width: 95%;
	}
}

@media only screen and (min-width: 1100px) {
	.container {
		max-width: 984px !important;
	}
}

@media only screen and (max-width: 1240px) {
	.heritageSection .our-heritage-link .description-flex {
		margin-right: 10px;
	}

	#ourwhisky-slider .buttons span {
		bottom: 20px;
	}

	.exploreSection .progressBarContainer {
		padding: 10px 120px;
		bottom: 40px;
	}

	.heritageSection .our-heritage-link {
		padding: 30px;
	}

	.secondSection .leftdscDiv {
		margin: 0 0 0 32px;
	}

	.secondSection .righrdscDiv {
		margin: 0 32px 0 auto;
	}

	.teams-container .slick-slider {
		padding: 0;
	}

	.distillery-slider-parent .slick-slider button.slick-prev,
	.distillery-slider-parent .slick-slider button.slick-next,
	.teams-container .slick-arrow {
		top: auto !important;
		bottom: 5px;
	}

	.distillery-slider-parent .slick-slider button.slick-next,
	.teams-container button.slick-next {
		bottom: -34px;
	}
	/* Changes by Avi */
	.pepleDiv{
		background-color: #000000;
	}
	.people-container .bg_people-image{
		background-size: contain;
		background-position: 50% 37px;
	}
	/* Changes by Avi ends */
}

@media screen and (max-width: 1025px) {
	.sliderSect_img button.slick-arrow {
		display: none!important;
	}
}

@media screen and (min-width: 991.5px) {
	.related-product:hover .related-product-image {
		opacity: 1;
		transition: all 250s ease 0s, height 250ms ease 0s;
		height: 540px;
	}
}

@media screen and (max-width: 991.5px) {
	.sliderSect_img{
		margin-top: 50px !important;
	}
	.people-container .sliderContainer .full-video{
		height: auto;
		object-fit: fill;
	}
	.sliderSect_img button.slick-arrow {
		display: block!important;  
	}
	.ctaDiv{
		margin-bottom: 60px;
	}
	.factsDataTitle.container, .factsDataTitle{
		position: unset;
	}
	.fact-description{
		font-size: 16px;
	}
	.factsDataTitle h3{
		padding: 0 !important;
	}	
	.searchboxDiv {
		position: absolute;
		right: -30px;
		background-color: transparent;
		height: 22px;
	}
	.searchbox {
		background-color: transparent;
	}
	.conterDiv{
		height: 68px;
	}
	.image-counter{
		top: 40px;
		padding: 0 5px;
	}
	.d-lg-flex.b-block.tabData{
		padding: 0 30px;
	}
	.home-last-section .text-content,
	.home-last-section .text-content .content-head {
		margin-right: 0;
		max-width: 100%;
	}

	div h3 {
		margin-bottom: 16px;
		font-size: 32px;
		line-height: 38px;
	}
	.heritageSection .section-lable {
		margin: 0;
	}

	#ourwhisky-slider .slick-slider {
		padding-left: 5%;
	}

	.heritageSection #pronounce {
		max-width: 100%;
		height: auto;
		margin: 20px 0;
	}

	#ourwhisky-slider .oban-image img {
		right: 32px;
	}

	.heritageSection .pronunciation a {
		position: initial;
		margin: 20px 0 !important;
	}

	.heritageSection .our-heritage-link {
		margin-bottom: 30px;
	}

	.heritageSection .our-heritage-link:last-child {
		margin: 0;
	}

	.heritageSection .our-heritage-link .description-flex {
		width: 50%;
	}

	.heritageSection .descImg {
		width: auto;
	}
	.factsData .factsItem .factsImg{
		min-height: 240px;
	}

	/* div h3, */
	.exploreSection .sliderContainer .slider-text h3 {
		margin-bottom: 16px;
		font-size: 32px;
		line-height: 38px;
	}

	div h2 {
		margin-bottom: 15px;
		font-size: 36px;
		line-height: 43px;
	}

	.description,
	.exploreSection .sliderContainer .slider-text p {
		font-size: 17px;
		line-height: 29px;
	}

	section.heritageSection{
		padding: 60px 18px;
	}
	
	.home-last-section {
		padding: 60px 0px;
	}

	footer {
		padding: 0;
	}

	.exploreSection .progressBarContainer {
		padding: 10px 50px;
		bottom: 40px;
	}

	/* .exploreSection .sliderContainer .slider-text{
		top: 15vh;
	}
	.index-page .hero{
		height: auto;
	} */
	div h1 {
		font-size: 40px;
		line-height: 55px;
	}

	.ourWhiskySection,
	#ourwhisky-slider,
	#ourwhisky-slider .slick-list,
	.ourWhiskySection .slick-vertical .slick-slide,
	#ourwhisky-slider .slick-slide .row .wiskey-slider-text:first-child,
	.index-page .hero,
	.full-video,
	.exploreSection .slider {
		height: 570px;
	}

	#ourwhisky-slider .slick-slide .row .wiskey-slider-text:first-child {
		margin-left: 25px;
	}

	/* #ourwhisky-slider .slick-dots{
		height: unset;
	} */
	.heritageSection .our-heritage-link img {
		max-width: 200px;
		width: auto;
	}

	.fixedHeader .find-cta-link, .fixedHeader .find-cta-link-dark {
		margin-right: 25px;
	}

	/* .main.whiskies .container{ */
	body .container {
		max-width: 910px;
		padding: 0 30px;
	}

	.secondSection .leftdscDiv,
	.secondSection .righrdscDiv {
		margin: 0 32px;
	}

	.secondSection .leftdscDiv,
	.secondSection .righrdscDiv,
	.whisky-last-section .text-content {
		max-width: 100%;
	}

	.distillery-page .fourthSection {
		margin-top: 60px;
	}

	.large-quote {
		font-size: 19px;
		line-height: 35px;
	}

	.distillery-slider .slick-slide img {
		max-width: 100%;
		margin: auto !important;
	}

	.distillery-slider .slick-list {
		/* height: 515px !important; */
		height: auto !important;
	}

	.heritage-section-tab .nav.nav-tabs {
		background-color: #000000;
		margin: 0;
		max-width: 100%;
	}

	.heritage-section-tab .nav.nav-tabs li button {
		color: #ffffff;
		font-size: 12px;
	}

	.factsDataTitle .row {
		align-items: center;
		height: 80px;
	}

	.factsData .factsItem {
		max-height: 100%;
	}

	.factsData .factsDesc {
		display: flex;
		align-items: center;
	}

	.factsDiv.w-50, .tabData .w-50 {
		width: 100% !important;
	}

	.factsDiv {
		height: auto;
		overflow: unset;
	}

	.gradient-cutoff {
		display: none;
	}

	.factsDataTitle {
		position: relative;
		max-width: 100% !important;
	}
	.factsData.container{
		max-width: 100% !important;
	}
	.factsData .factsItem{
		padding: 30px;
	}

	.related-product-information {
		position: static;
		opacity: 1;
		text-align: center;
		padding-top: 25px;
		border-top: 1px solid rgba(0, 0, 0, 0.17);
	}

	.related-products .related-product {
		max-width: fit-content;
	}

	.related-products {
		height: auto;
	}

	.related-products .related-product-image {
		height: 290px;
		margin: auto;
	}

	.distillery-slider-parent .slick-slider button.slick-prev {
		left: 30px;
	}

	.distillery-slider-parent .slick-slider button.slick-next {
		right: 30px;
	}

	.distillery-slider .slick-slide {
		padding: 0;
	}

	.distillery-slider-parent .slick-slider button.slick-prev {
		bottom: -42px;
	}

	.distillery-slider-parent .slick-slider button.slick-next {
		bottom: -82px;
	}

	.distillery-page .slick-dots {
		bottom: -52px;
	}

	.distillery-slider {
		padding: 60px 0 80px;
	}
	#ourwhisky-slider .buttons span{
		display: none;
	}

	.section-label.dark {
		display: none;
	}
	
	.section-lable.absolute, .section-label{
		display: none;
	}
	.video-modal{
		padding-left: 0;
		padding-right: 0;
		margin: 0 !important;
		max-width: 100%;
	}
	.video-modal .exit-button {
		top: -24px;
		right: 20px;
	}
	.height_100vh.pepleDiv {
		height: 75vh;
	}
	.video-section{
		padding: 0;
	}
	/* changes by AVI */
	.large-quote.larger{
		font-size: 30px;
		line-height: 50px;
	}
	/* changes by AVI ends */

	/* Minal */
	.absoluteHeader .find-cta-link, .absoluteHeader .find-cta-link-dark {
		margin-right: 25px;
	}
	.where-to-buy .heading h1 {
		font-size: 44px;
	}
	/* Minal */
}

@media screen and (max-width: 820.5px) {
	.distillery-page .we-never .cta.underline {
		bottom: -115px;
	}
}

@media screen and (max-width: 768.5px) {
	.teams-member .middle{
		visibility: visible !important;
		text-align: center;
		top: -90px;
	}
	.teams-member .middle h4{
		font-size: 21px;
		line-height: 21px;
	}
	.teams-member .middle span{
		font-size: 14px;
	}
	.hero-content{
		padding: 0;
	}
	.video-modal .full-video{
		object-fit: contain;
	}
	h5.title{
		font-size: 21px;
		line-height: 31px;
		padding-right: 22px;
	}
	.factsData .factsItem{
		padding: 30px;
	}
	.factsImg img.img-fluid{
		height: 190px;
		float: right;
		object-fit: cover;
	}
}
@media screen and (max-width: 767.5px) {

	.fixedHeader .find-near-me .find-cta-link,
	.fixedHeader .find-cta-link-dark,
	.distillery-page .quoteDiv .large-quote br {
		display: none !important;
	}
	.progressBarContainer .people-name .usern-nm {
		white-space: normal;
		line-height: 1.4;
	}
	.play-button-people, .pause-button-people{
		top: 20vw;
	}
	.component-heading.margin-bottom {
		margin-bottom: 30px;
	}

	.kicker {
		float: none;
		max-width: 100%;
		font-size: 19px;
		margin: 0;
	}

	.hero-content h1 {
		max-width: 325px;
	}

	.fixedHeader .find-near-me span {
		margin-left: auto;
	}

	.home-last-section .inline-link {
		position: unset;
		margin: 20px 0 50px;
	}

	.footer-image.home img {
		width: 100%;
		height: 490px;
	}

	.people-container .footer-image.home img {
		width: 100%;
		height: 490px;
		float: right;
		background: #faf6f3;
		padding-left: 38.5%;
		max-width: 100%;
	}

	#ourwhisky-slider .align-middle {
		max-width: 300px;
	}

	#ourwhisky-slider .oban-image img {
		max-width: 215px;
		height: 90%;
		right: 10px;
	}

	.exploreSection .sliderContainer .slider-text {
		width: 100%;
		max-width: 83%;
	}

	.exploreSection .slick-prev,
	.exploreSection .slick-next {
		top: unset;
		bottom: 74px;
	}

	#ourwhisky-slider .slick-dots {
		top: 50px;
		transform: unset;
		display: flex !important;
		left: 5px;
		height: fit-content;
	}

	#ourwhisky-slider,
	.ourWhiskySection .slick-vertical .slick-slide,
	.ourWhiskySection {
		height: 470px;
	}

	#ourwhisky-slider .slick-slider,
	#ourwhisky-slider .slick-slide .row .wiskey-slider-text:first-child {
		padding-left: 0px;
	}

	/* #ourwhisky-slider .slick-slide .row .wiskey-slider-text:first-child{
		display: inline;
		margin-top: 100px;
	} */
	.container.heading {
		padding: 60px 30px;
	}

	.secondSection .dscDiv,
	.secondSection .videoDiv {
		width: 100%;
		align-items: start;
	}

	.secondSection .full-video {
		max-height: 380px;
	}

	.hero.products.secondSection .dataDiv {
		flex-direction: column;
	}

	.hero.products.secondSection .dataDiv.reverse {
		flex-direction: column-reverse;
	}

	.secondSection .dscDiv {
		height: 500px;
		padding-top: 60px;
	}

	.distillery-page .quoteDiv {
		max-width: 100%;
	}

	.distillery-page .that-why {
		padding: 70px 0 41px;
	}

	.large-quote.larger {
		font-size: 25px;
		line-height: 41px;
	}

	.distillery-page .we-never {
		height: 420px;
	}

	.distillery-page .secondSection,
	.distillery-page .thirdSection,
	.teams-container,
	.people-container .teams-container {
		padding: 60px 0;
	}

	.exploreSection .progressBarContainer {
		padding: 10px 30px;
		max-width: 100%;
		width: 100%;
	}

	.exploreSection .progressBarContainer {
		bottom: 45px;
	}

	.product-details .image.img-fluid {
		width: 120px;
		margin-bottom: 50px;
	}

	.product-details .firstSection {
		padding: 140px 30px 60px;
	}

	.product-details .productsTabs {
		width: 100% !important;
		margin-top: 50px !important;
	}

	.related-products .related-product-image {
		height: 410px;
	}

	.related-products {
		flex-direction: column;
		align-items: center;
	}

	.related-product-information,
	.related-products .related-product {
		width: 100%;
		max-width: 100%;
	}

	.slick-slide {
		text-align: center;
	}

	#ourwhisky-slider .slick-slide .row .wiskey-slider-text:first-child {
		margin-left: 7px;
	}

	.distillery-slider .slick-slide img {
		padding: 0;
	}
	.teams-member {
		padding: 0 15px;
	}
	.main.where-to-buy .image-footer-responsive{
		height: 490px;
		object-fit: cover;
	}
}

@media screen and (max-width: 650px) {
	.heritage-section-tab .nav.nav-tabs {
		display: block;
	}
}

@media screen and (max-width: 515.5px) {
	#ourwhisky-slider .align-middle {
		max-width: 93%;
	}

	#ourwhisky-slider .slick-slide .row .wiskey-slider-text:first-child {
		height: auto;
	}

	#ourwhisky-slider,
	.ourWhiskySection .slick-vertical .slick-slide,
	.ourWhiskySection {
		height: auto;
	}

	#ourwhisky-slider .buttons span {
		position: inherit;
		font-size: 15px;
	}

	#ourwhisky-slider .buttons span a {
		font-size: 13px;
	}

	#ourwhisky-slider .oban-image img {
		position: relative;
		max-width: 168px;
		margin: auto;
		height: 410px;
		bottom: -60px;
		left: 0;
		right: 0;
	}

	#ourwhisky-slider .buttons button.button {
		max-width: 100%;
		width: 100%;
	}

	#ourwhisky-slider .slick-list {
		height: 800px !important;
		padding-top: 120px;
	}

	.our-heritage-link .d-flex.align-items-center.align-items-lg-start {
		display: block !important;
	}

	.heritageSection .our-heritage-link .description-flex {
		width: 100%;
	}

	.heritageSection .our-heritage-link img {
		max-width: 100%;
		height: 245px;
		width: 100%;
	}

	.exploreSection .sliderContainer .slider-text {
		top: 20vh;
	}

	.exploreSection .sliderContainer .slider-text {
		width: 76%;
	}

	.hero-content {
	  padding: 0 5px;
	}

	/* .distillery-page .we-never .cta.underline{
		position: inherit;
		bottom: 0;
	} */
	.distillery-page .we-never,
	.our-values {
		padding: 60px 0 35px;
	}

	.distillery-page .that-why p {
		margin: 0;
	}

	.distillery-page .we-never .cta.underline {
		position: initial;
	}
	.searchbox {
		background-size: auto 15px;
	} 
	
}

@media screen and (max-width: 768px) {
	.distillery-slider .slick-slide img {
		margin: 0 !important;
		width: 100%;
		padding: 0 30px;
	  }
}

@media screen and (max-width: 480.5px) {
	.distillery-page .slick-dots {
		display: none !important;
	}
}

@media screen and (max-width: 477.5px) {
	.cta.footer-cta {
		margin-bottom: 46px;
		font-size: 14px;
	}
	.exploreSection .progressBarContainer{
		bottom: 0;
	}

	.cta-link {
		font-size: 14px;
	}

	.fixedHeader .find-near-me,
	.mobile-menu span,
	.page-name-light,
	.page-name,
	.page-name-light,
	.page-name-dark-title,
	.page-name-dark,
	.usern-fn,
	.us-name {
		display: none !important;
	}

	.menu-icon,
	.menu-icon-dark {
		width: 25px;
		margin-top: -8px;
	}

	header .logo img {
		width: 85px;
	}

	.menu-item {
		font-size: 28px;
		line-height: 54px;
	}

	div h1 {
		font-size: 32px;
		line-height: 44px;
	}

	div h2.text-dark,.secondSection h2.text-dark,
	div h2 {
		font-size: 28px;
		line-height: 32px;
		/* margin-bottom: 0; */
	}

	.secondSection h2 {
		font-size: 32px;
		line-height: 32px;
	}

	button.button {
		max-width: 100%;
		width: 100%;
	}

	.whisky-last-section .container {
		/* padding: 0; */
	}
	.kicker {
		font-size: 17px;
		line-height: 29px;
	}
	#ourwhisky-slider .slick-slide.slick-active.slick-current {
		z-index: 99;
		position: absolute !important;
		top: initial !important;
	}
	.whiskies .firstSection {
		padding-top: 85px;
	}
	.distillery-page .firstSection .textContain,
	.people-container .people-image {
		bottom: 100px;
	}
	.people-container .people-image {
		position: relative;
		bottom: -10px;
		/* background-color: #000000; */
		padding: 60px 0 20px 0;
	}
	.people-container .people-image h1 {
		font-size: 17px;
		line-height: 21px;
		padding-bottom: 10px !important;
	}
	.height_100vh,
	.distillery-page .bg_firstSection,.heritage-image-bg {
		height: 90vh;
		background-size: cover;
	}
	.people-container .bg_people-image {
		/* changes by AVI */
		/* height: 24vh;
		background-size: 65%; */
		height: 60vh;
		/* background-position: 0 0; */
		background-attachment: scroll;
		/* changes by AVI ends*/
	}
	/* changes by AVI */
	.height_100vh.pepleDiv {
		height: 85vh;
	}
	.people-container .people-image{
		padding: 30px 0 20px 0;
    	bottom: 110px;
	}
	.people-container .people-image p.kicker{
		font-size: 15px;
		line-height: 1.5;
	}
	/* Changes by AVI ends here */
	.distillery-page .secondSection {
		text-align: start;
	}
	.distillery-page .thirdSection .videoDiv video {
		min-height: 340px;
	}
	.large-quote.larger {
		font-size: 24px;
		line-height: 31px;
	}
	.home-last-section .container {
		/* padding: 0; */
	}
	.pepleDiv {
		padding-top: 110px;
		background-color: #000000;
		height: 78vh;
	}
  
	.product-details .container.firstSection .d-flex.mb-4 {
	  display: flow-root !important;
	}

	/* Minal */
	.absoluteHeader .find-near-me,
	.mobile-menu span,
	.page-name-light,
	.page-name,
	.page-name-light,
	.page-name-dark-title,
	.page-name-dark,
	.usern-fn,
	.us-name,
	.page-name-light-title{
		display: none !important;
	}
	/* Minal */
}

@media (max-width: 767px) {
	#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li {
		width: 50%;
	}
	#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_1,
	#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_3,
	#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_5 {
		width: 50%;
	}
	.ctaDiv{
		padding-bottom: 30px;
	}
}

@media (max-width: 479px) {
	#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li {
		width: 100%;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
	#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_1,
	#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_3,
	#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_5 {
		width: 100%;
	}
	#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_7 {
		width: 100% !important;
	}
	#footer
	  #dg_footer_wrapper
	  #dg_footer_wrapper_inner
	  ul
	  li.dg_footer_item_7
	  span {
		width: 100%;
	}
	
	.social-links-wrapper--mobile {
		margin-top: 20px;
		display: block;
		justify-content: space-around;
		display: flex;
	}
	.social-bar-icon.facebook {
		height: 21px;
		margin-right: 27px;
	}
	.social-bar-icon.twitter {
		height: 18px;
		margin-right: 25px;
		margin-left: 0px;
	}
	.social-bar-icon.instagram {
	  height: 21px;
	}
	header .logo {
		left: -50px;
	}
	.bg-home-slider .background-video .full-video, .index-page .hero{
		height: 90vh !important;
	}
	.factsData .factsItem {
		flex-direction: column;
	}
	.factsData .factsItem .factsDesc, .factsData .factsItem .factsImg{
		width: 100%;
		max-width: 100%;
		padding: 0;
	}
	.factsData .factsItem .factsImg{
		background-position: 50% 22%;
	}
	.factsImg img.img-fluid{
		height: 245px;
		width: 100%;
		float: none;
	}
	h5.title {
		font-size: 19px;
		line-height: 26px;
		padding-right: 12px;
		margin-bottom: 10px;
	}
	.footer-image.home img {
		width: 100%;
		height: 250px;
	}
	.people-container .footer-image.home img {
		width: 100%;
		height: 250px;
	}
	.ctaDiv {
		border-color: rgba(0, 0, 0, .11);
	}
	.footer {
		background-position: 168% 86%;
		background-size: auto 140px;
	}
	.mt120{
		margin-top: 40px !important;
	}
	.distillery-page .we-never .container {
		height: 446px;
	}
	.distillery-page .we-never {
		background-image: linear-gradient(180deg, rgba(0, 0, 0, .16), rgba(0, 0, 0, .16)), url('../public/assets/images/bird.jpg');
		background-position: 0px 0px, 100% 0px;
		background-size: auto, cover;
		background-attachment: scroll, scroll;
		height: 446px;
	}
	.distillery-page .we-never .cta.underline{
		position: relative;
		bottom: -105px;
	}
	
	.product-details .image.img-fluid {
	  width: 70px;
	}
  
	.awards-preview .seal-image {
	  margin-top: 5px;
	  margin-bottom: 5px;
	  float: none;
	}

	/* Minal */
	.where-to-buy .heading h1 {
		font-size: 32px;
	}
	/* Minal */
	.whiskies .firstSection.hero.products {
		padding-top: 40px;
		background-position: 100% 0px;
		background-size: cover;
		margin-top: 35px;
	}
	.main.whiskies .where-buy-section div h1{
		font-size: 28px;
		color: #000000;
	}
	.fact-description {
		opacity: 0.62;
		font-size: 15px;
		line-height: 25px;
	}
	.main.where-to-buy .image-footer-responsive{
		height: 250px;
	}
}


@media (max-width: 360px) {
	.searchboxDiv {
		right: 2px;
	}
	.searchbox {
		background-position-x: calc(100% - 4px)!important;
		background-size: auto 15px;
	}
	.people-container .footer-image.home img {
		padding-left: 30.5%;
	}
}
@media (max-width: 437px) {
	.distillery-page .we-never{
		height: 510px;
	}
	.textDiv {
		padding: 0 !important;
	}
}
@media only screen and (max-width: 733px) {
	.eMargin label{
		margin-left: 0;
	}
}
@media only screen and (max-width: 991.5px) {
	.modal .eMargin label{
		margin-left: 0 !important;
	}
}